import { AppLang } from '@/lang/_template.type';

const br: AppLang = {
	app_name: 'Pixcap',
	promo_banner: {
		title:
			'Aproveite {discount}% de desconto nos planos Pro e Elite Annual com o código promocional: <span class="text-weight-600">{promo_code}!</span>',
		cta_text: 'Ganhe {discount}% de desconto',
	},
	navbar: {
		search_placeholder: 'Pesquisar...',
		search_dropdown: {
			recent_search: 'Pesquisa recente',
			popular_search: 'Pesquisa popular',
			suggested_search: 'Pesquisa sugerida',
		},
		account: 'Conta',
		navigation_items: {
			browse: 'Navegar',
			asset: 'Ativos 3D',
			asset_dropdown: {
				icons_title: 'Ícones 3D',
				icons_description: 'Mais de 15.000 ícones e ilustrações 3D gratuitos e premium',
				characters_title: 'Personagens 3D',
				characters_description: 'Mais de 1.000 personagens 3D gratuitos e premium',
				mockups_title: 'Mockups',
				mockups_description: 'Mais de 500 mockups gratuitos e premium e kits de mockups de marcas',
			},
			tools: 'Ferramentas',
			tools_dropdown: {
				prefix: 'Melhores ferramentas de IA',
				ai_3d_model: 'Gerador de modelo 3D AI',
				ai_character: 'Gerador de caracteres de IA',
				ai_girl: 'Gerador de garota AI',
				ai_background: 'Gerador de plano de fundo de IA',
				ai_filter: 'Filtro AI',
				ai_icon: 'Gerador de ícones de IA',
				icon_maker: 'Criador de ícones 3D',
				animated_icon: 'Ícone animado',
				character_creator: 'Criador de personagens 3D',
			},
			pricing: 'Preços',
		},

		language_switcher: {
			tooltip: 'Idioma',
		},

		my_projects: 'Meus projetos',

		login: 'Login',
		notification_dropdown: {
			notifications: 'Notificações',
			empty: 'Você não recebeu nenhuma notificação',
		},
		download_dropdown: {
			full_download_history: 'Histórico completo de downloads',
			tooltip: {
				download_history: 'Histórico de downloads',
				download_completed: 'Download concluído',
			},
			show_all_downloads: 'Mostrar todos os downloads',
			empty: 'Está vazio aqui. \n Tente fazer o download (alta qualidade) \n para ver onde a mágica acontece.',
			upgrade: {
				enjoy: 'Você sabia que os usuários do Pro/Elite desfrutam de <span class="text-color-pro">3x faster renders?</span>',
				text: 'Faça o upgrade agora para obter um aumento na velocidade de download de alta qualidade!',
				upgrade_now: 'Faça o upgrade agora',
			},
			cancel_download_modal: {
				cancel_download: 'Cancelar download de alta qualidade',
				text: 'Você perderá o saldo usado para este vídeo. Tem certeza de que deseja continuar?',
				text_gif: 'Você perderá o saldo usado para esse GIF. Tem certeza de que deseja continuar?',
				confirm: 'Confirmar',
			},
		},
		user_dropdown: {
			projects: 'Projetos',
			downloads: 'Downloads',
			account_settings: 'Configurações da conta',
			invite_friends: 'Invite Friends',
			contact_us: 'Convidar amigos',
			shared_project: 'Projetos compartilhados',
			contributor_files: 'Arquivos do colaborador',
			sign_out: 'Sair',
		},
		login_form: {
			title: 'Desbloqueie mais de 15.000 ativos 3D personalizáveis',
			description: 'Ícones animados em 3D de alta qualidade, personagens 3D e mockups.',
			google_login: 'Continuar com o Google',
			create_account: 'Criar uma conta',
			create_account_heading: 'Você está criando uma conta Pixcap com',
			accept_term: 'Ao continuar, você concorda com nossos',
			next: 'Próximo',
			verify: 'Verificar',
			back: 'Voltar',
			email_login: {
				email: 'Digite o e-mail',
				password: 'Digite a senha',
				confirm_password: 'Confirmar a nova senha',
				resend: 'Reenviar',
				email_login: 'Continuar com o e-mail',
				enter_email: 'Digite o endereço de e-mail',
				enter_verification_code: 'Digite o código de verificação',
				verification_sent: 'Enviamos um código de verificação para',
				enter_email_message: `Faremos seu login ou criaremos uma conta<br /> se você ainda não tiver uma.`,
				verify_email: 'Verificar e-mail',
				check_email: 'Verifique seu e-mail em <span class="text-color-primary-5">{{ email }}</span>  para obter um código para verificar seu e-mail.',
				invalid_code: 'O código inserido não é válido, tente novamente',
				did_not_received: 'Não recebeu o e-mail?',
				error_email: 'Digite um e-mail válido.',
				error_E02_02_001: 'O e-mail ou a senha está incorreto.',
				error_E02_02_002: 'Este e-mail já está em uso',
				error_E02_02_003: 'Algo deu errado, tente novamente',
				error_default: 'Falha no login, tente novamente',
			},
		},
		forgot_pw_form: {
			forgot_pw: 'Esqueceu sua senha? ',
			sub: 'Digite seu endereço de e-mail para redefinir sua senha.',
			check_your_email: 'Enviamos a você um e-mail para <strong>{email}</strong> com um código de redefinição de senha.',
			sub_text: 'Enviamos a você um e-mail com o link de redefinição de senha.',
			did_not_received: 'Não recebeu o e-mail?',
			enter_reset_code: 'Digite o código de redefinição',
			reset_password: 'Redefinir sua senha',
			error_email: 'O e-mail é inválido',
			change_password: 'Alterar senha',
			continue: 'Continuar',
		},
		contact_us_form: {
			contact_us: 'Entre em contato conosco',
			name: 'Nome',
			enter_your_name: 'Digite seu nome',
			email: 'E-mail',
			enter_your_email: 'Digite seu e-mail',
			message: 'Sua mensagem',
			write_message: 'Escreva sua mensagem aqui',
			send: 'Enviar mensagem',
			error_name: '**O nome não pode estar vazio',
			error_email: '**O e-mail é inválido',
		},
	},
	sidebar: {
		home: 'Início',
		library: 'Biblioteca',
		my_projects: 'Meus projetos',
		my_assets: 'Meus ativos',
		pricing: 'Preços',
		unlock_all_features: 'Desbloquear todos os recursos',
		upgrade_plan: 'Plano de upgrade',
	},
	breadcrumb: {
		home: 'Início',
		mockups: 'Mockups',
		brandkit_mockups: 'Mockups de kit de marca',
		icon_packs: 'Pacotes de ícones 3D',
		illustrations: 'Ilustrações',
		designs: 'Designs',
	},
	footer: {
		title: 'Explore mais conteúdo 3D',
		searched_title: 'Procure pesquisas relevantes',
		seo_section: {
			sort_by: {
				latest: 'Mais recentes',
				trending: 'Tendências',
			},
		},
		footer_section: {
			explore: {
				title: 'Explorar',
			},
			features: {
				title: 'Recursos',
				animated_mockup: 'Mockups animados',
				ai_style_transfer: 'Transferência de estilo de IA',
				character_library: 'Biblioteca de personagens',
				material_library: 'Biblioteca de materiais',
			},
			company: {
				title: 'Empresa',
				pricing: 'Preços',
				enterprise: 'Empresa',
				privacy_policy: 'Política de privacidade',
				terms_of_service: 'Termos e condições',
				contact_us: 'Entre em contato conosco',
			},
			resources: {
				title: 'Recursos',
				glossary: 'Glossário',
				contributor: 'Torne-se um colaborador',
			},
			tools: {
				title: 'Ferramentas',
				ai_3d_model: 'Gerador de modelo 3D AI',
				ai_character: 'Gerador de caracteres de IA',
				ai_girl: 'Gerador de garota AI',
				ai_background: 'Gerador de plano de fundo de IA',
			},
			create: {
				title: 'Criar',
				mug_design: 'Design da caneca',
				sticker_design: 'Design de adesivos',
				friendsgiving_invitation: 'Convite de Ação de Amigos',
				poster_mockup: 'Mockup de pôsteres',
				iphone_mockup: 'Mockup de iPhone',
			},
			blogs: {
				title: 'Blogs',
				all_blogs: 'Todos os blogs',
			},
			follow_us: {
				title: 'Siga-nos',
			},
		},
	},

	page: {
		home: {
			title: 'O único mercado para <br> <span class=“text-weight-600 text-color-primary-5”>Ativos 3D editáveis</span>',
			description: 'Edite ícones 3D, personagens 3D, maquetes e ilustrações 3D de alta qualidade em seu navegador da Web.',
			partners: {
				title: 'Usado por equipes em',
			},
			interactive_banner: {
				title: 'Crie facilmente elementos 3D exclusivos',
				description: 'Experimente nosso editor simples para ajustar cores, materiais e estilos de animação de qualquer ativo 3D.',
				default: 'Padrão',
				color: 'Cor',
			},
			animated_icon_pack: 'Ícones 3D animados',
			animated_icon_pack_description: 'Personalize ícones animados em 3D alterando cores, girando a câmera, ajustando materiais e muito mais.',
			icon_pack: 'Ícones 3D',
			icon_pack_description: 'Personalize os ícones 3D alterando as cores, girando a câmera, ajustando os materiais e muito mais.',
			characters_pack_description: 'Crie personagens e ilustrações em 3D exclusivos, alterando poses e cores.',
			device_mockup_pack_description:
				'Carregue seu rótulo e crie mockups exclusivos de UX/UI. Personalize as cores e faça o download em MP4, PNG ou GIF.',
			branding_mockup_pack_description:
				'Faça o upload de sua etiqueta e crie modelos de marca exclusivos. Personalize as cores e faça o download em MP4, PNG ou GIF.',
			latest_icon_pack: 'Ícones 3D mais recentes',
			characters_pack: 'Personagens 3D',
			device_mockup_pack: 'Mockups de dispositivos',
			branding_mockup_pack: 'Mockups de marcas',
			advertise: {
				title: 'A melhor ferramenta de design 3D para seus projetos',
				description: 'Edite e use modelos 3D para web design, aplicativos móveis, marketing e muito mais',
				first_card: {
					title: 'Site e aplicativo móvel',
					description:
						'Use elementos 3D para aprimorar o design de seus projetos. Chame a atenção dos usuários e faça com que seu site e aplicativo se destaquem da concorrência.',
				},
				second_card: {
					title: 'Mockups animados',
					description:
						'Carregue seu design em qualquer mockup animado, edite o plano de fundo de acordo com sua preferência e faça o download em 4K em menos de 5 minutos.',
				},
				third_card: {
					title: 'Apresentação',
					description:
						'Utilize personagens e ícones 3D para apresentações. Torne seu discurso de negócios ou sua apresentação educacional mais envolvente e dinâmica.',
				},
			},
			compatible: {
				title: 'Compatível com seu software favorito',
				description: 'Baixe o conteúdo do Pixcap em qualquer tipo de arquivo - GLB, MP4, GIF, PNG para usar com todos os seus softwares favoritos.',
			},

			banner: {
				title_desktop: `Comece a personalizar <br> qualquer conteúdo 3D!`,
				title_mobile: `Comece a editar qualquer <br> Conteúdo 3D!`,
				button_text: 'Explore agora',
			},

			header1: 'Todos os itens 3D são personalizáveis - Experimente agora!',
			trending_icons: 'Ícones de tendências',
			essential_icon: 'Pacotes de ícones essenciais',
			essential_illustrations: 'Kits essenciais de ilustração',
			essential_mockups: 'Mockups realistas de dispositivos e marcas',

			header2: 'Procure mais criativos 3D editáveis',
			fintech_illustrations: 'Ilustrações de fintechs',
			ecommerce_illustrations: 'Ilustrações de comércio eletrônico',
			healthcare_illustrations: 'Ilustrações do setor de saúde',
			browse_all_illustrations: 'Procurar todas as ilustrações',
			business_characters: 'Personagens de negócios',
			casual_characters: 'Personagens casuais',
			profession_characters: 'Personagens de profissão',
			browse_all_characters: 'Procurar todos os personagens',
			laptop_mockups: 'Mockups de laptops',
			smartphone_mockups: 'Mockups de smartphones',
			branding_mockups: 'Mockups de marca',
			browse_all_mockups: 'Procurar todos os mockups',
		},
		library: {
			banner: {
				title_icons: 'Personalizar e editar 3D {animate} {tag} Pacotes de ícones',
				title_characters: 'Editar e fazer poses em 3D {tag} caracteres',
				title_mockups: 'Aplicar designs a dispositivos e marcas {tag} maquetes',
				description_icons:
					'Experimente nosso Editor para ajustar as cores, os materiais e os estilos de animação de seu 3D {animate} ícones. Baixe ícones 3D gratuitos em PNG, GLB, GIF, MP4 para uso no Figma, Sketch, Adobe e muito mais.',
				description_characters:
					'Descubra a única ferramenta de poses em 3D que permite que você faça poses de seus personagens 3D, modifique cores e muito mais, sem a necessidade de conhecimento em modelagem 3D.',
				description_mockups:
					'Os mockups agora são totalmente personalizáveis - carregue seu rótulo em qualquer mockup animado e faça o download em 4K em menos de 5 minutos.',
			},
			title: {
				prefix_customize: 'Personalizar',
				prefix_free: 'gratuito',
				prefix_premium: 'prêmio',
				prefix_free_and_premium: 'gratuito e premium',
				icons: 'Pacotes de ícones 3D',
				characters: 'Personagens 3D',
				mockups: 'mockups',
			},
			description: {
				icons:
					'Escolha entre os conjuntos de ícones de alta qualidade {count} {premium} {animated} {tag} para sites, aplicativos, design gráfico e marketing. Personalize e baixe ícones em PNG, GLB, GIF e MP4 para projetos de design.',
				characters:
					'Aumente o envolvimento do usuário e a narrativa com ilustrações de personagens {premium} {tag}. Personalize e use-as em publicações de mídia social, apresentações, vídeos explicativos, demonstrações de produtos e muito mais.',
				mockups:
					'Aprimore seus designs e apresentações com  {premium} {animated} {tag} mockups realistas. Facilmente personalizáveis e perfeitos para mostrar produtos, marcas, embalagens e muito mais.',
			},
			header: 'Explore conteúdo 3D totalmente personalizável!',
			cards: {
				icons: {
					icons: 'Ícones',
					browse_all: 'Procurar todos os ícones 3D',
				},
				characters: {
					characters: 'Personagens',
					browse_all: 'Procurar todos os personagens 3D',
				},
				illustrations: {
					illustrations: 'Ilustrações',
					browse_all: 'Procurar todas as ilustrações em 3D',
				},
				mockups: {
					mockups: 'Mockups',
					branding_kits: 'Kits de mockup de marca',
					browse_all: 'Navegue por todos os mockups 3D realistas',
				},
				designs: {
					designs: 'Designs',
					browse_all: 'Navegue por todos os designs 3D',
				},
			},
		},
		pack_details: {
			mockup_descriptions:
				'Desperte inspiração instantânea e mostre o design de sua marca com nossa biblioteca de modelos de mockups profissionais de branding. De cartões de visita elegantes e embalagens atraentes a ativos impressionantes de mídia social e mockups de sites, temos tudo o que você precisa para visualizar a identidade da sua marca em um ambiente realista.',
			features: {
				premium_asset: 'Todos os ativos premium',
				exclusive_materials: 'Materiais e predefinições exclusivos',
				exclusive_poses: 'Poses de personagens exclusivas',
				commercial_license: 'Licença comercial',
				export: 'Exportação de arquivos 3D',
				edit: 'Editar cores, materiais e predefinições',
				downloads: 'Downloads de alta qualidade',
				elite_unlimited_images: 'Imagens de alta qualidade ilimitadas',
				elite_video_per_month: '30 vídeos de alta qualidade por mês',
			},
			get_all_access: 'Obter acesso total',

			info: {
				info: 'Informações',
				number_of_items: 'Número de itens',
				file_type: 'Tipo de arquivo',
				compatible: 'Compatível com',
				release: 'Lançamento',
				author: 'Autor',
			},
			preview: {
				preview: 'Pré-visualização',
				default: {
					render: 'Renderiza usando o mecanismo padrão do navegador',
					shadow: 'Sombras duras',
				},
				hq: {
					render: 'Renderiza usando o mecanismo otimizado do nosso servidor',
					shadow: 'Ray tracing, sombras e iluminação realistas',
				},
			},
			mockup_pack_description:
				'O pacote de mockups {pack_name} é uma coleção de mockups {pack_name} de alta qualidade, perfeitos para exibir seus designs de forma profissional. <br> O pacote de mockups {pack_name} permite que você personalize e apresente seus designs com facilidade. Basta fazer o upload do seu design, editar o objeto e o fundo para criar um mockup realista. <br> Todos os mockups {pack_name} estão disponíveis em PNG, GIF e MP4 para uso em várias plataformas.',
			pack_description:
				'{pack_name} é um conjunto de ícones 3D e ícones animados perfeitos para UX/UI, design gráfico e marketing. <br> O {pack_name} inclui uma grande variedade de ícones para todas as suas necessidades e mantém um esquema de cores consistente para um visual unificado. <br> Todos os ícones {pack_name} estão disponíveis em PNG, GLB, GIF e MP4 para uso em várias plataformas.',
			character_pack_description:
				'O pacote de personagens {pack_name} é um conjunto de personagens 3D e personagens animados perfeitos para websites, animações, design gráfico, marketing e apresentações. <br> O pacote oferece opções de personalização para cada personagem, permitindo que você altere o tom de pele, cor do cabelo, pose 3D e muito mais. <br> Todos os personagens {pack_name} estão disponíveis em PNG, GLB, GIF e MP4 para uso em várias plataformas.',
			browse_more: 'Explore mais criativos 3D editáveis',
			related_to_this_pack: 'Relacionados a este pacote',
		},
		item_details: {
			features: {
				edit: 'Editar cores, materiais, iluminação,...',
				edit_character: 'Editar poses, cores, materiais, iluminação,...',
				edit_mockup: 'Substituir imagens, editar cores, materiais, iluminação,...',
				downloads: 'Downloads de alta qualidade',
				export: 'Exportar arquivos 3D',
				ai: 'Experimentar Transferência de Estilo de IA',

				realistic: 'Mockup 3D realista',
				editable: 'Design e fundo editáveis',
				easy: 'Ferramentas fáceis de usar',
				high_quality: 'Exportação de alta qualidade',
				free: 'Grátis para uso comercial',
				customize: 'Formas, cores e materiais personalizáveis',
				high_resolution: 'Design 3D de alta resolução',

				file_types: 'Tipos de arquivo PNG, GLB',
				free_license: 'Licença gratuita para projetos comerciais e pessoais',
				ready_made: 'Modelos prontos e personalizáveis',
				easy_switch: 'Fácil alternância para vários tamanhos',
				file_types_2: 'Tipo de arquivo PNG',
				item_description:
					'{item_name} é o ícone perfeito para web, aplicativos, UX/UI, design gráfico e marketing. <br> Você tem capacidades de personalização completas com {item_name}, incluindo alteração de cores, materiais e estilos para se adequar aos seus projetos de design. <br> Baixe {item_name} em PNG, GLB, GIF e MP4 e use no Figma, Blender, Adobe e muito mais.',
				character_description:
					'{item_name} é o personagem perfeito para websites, animações, design gráfico, marketing e apresentações.<br> Você tem capacidades de personalização completas com o {item_name}, incluindo alteração do tom de pele, cor do cabelo, pose 3D e muito mais. <br> Disponível nos formatos de arquivo PNG, GLB.',
				mockup_description:
					'Este mockup {item_name} é perfeito para exibir sua marca, logotipo, layout de website e muito mais. <br> Você pode facilmente personalizar o objeto, o fundo e adicionar seus próprios elementos de design para torná-lo verdadeiramente único. <br> O mockup {item_name} vem em vários formatos de arquivo como PNG, GIF e MP4 para fácil edição e uso em várias plataformas.',
			},

			open_in_mnm: 'Abrir no Mix-n-match',
			edit_this_asset: 'Editar este ativo',

			more_from_this_pack: 'Mais deste pacote',
			people_also_downloaded: 'Pessoas também baixaram',
			text_3d_graphic: 'Gráfico 3D {item_name}',
			text_3d_illustration: 'Ilustração 3D {item_name}',

			more_from_this_bundle: 'Modelos neste pacote',
			related: '{resouce_type} relacionados',
		},
		search: {
			explore: 'Explorar',
			prefix_free: 'grátis',
			prefix_premium: 'premium',
			prefix_free_and_premium: 'grátis e premium',
			icon_packs: 'pacotes de ícones',
			character_packs: 'pacotes de personagens',
			mockup_packs: 'pacotes de mockups',
			asset_packs: 'pacotes de ativos',
			icon: 'ícone',
			icons: 'ícones',
			character: 'personagem',
			characters: 'personagens',
			mockup: 'mockup',
			mockups: 'mockups',
			asset: 'ativo',
			assets: 'ativos',

			description: {
				suffix_animated: 'Disponível nos formatos de arquivo PNG, GLB, GIF, MP4.',
				suffix_pack: 'Experimente pacotes animados de {search} {type} para capturar a atenção dos usuários.',
				prefix_pack: 'Personalize e baixe',
				suffix_icon_packs: 'pacotes para seus próximos projetos de design.',
				suffix_character_packs: 'pacotes para web, aplicativos, UX/UI, design gráfico e marketing.',
				suffix_mockup_packs: 'pacotes para apresentações.',
				suffix_asset_packs: 'pacotes para seus próximos projetos de design.',
				suffix_item: 'Experimente {search} {type} animados para capturar a atenção dos usuários.',
				prefix_item: 'Personalize e baixe',
				suffix_icon_items: 'ícones, ilustrações, símbolos e logotipos para seus próximos projetos de design.',
				suffix_character_items: 'para web, aplicativos, UX/UI, design gráfico e marketing.',
				suffix_mockup_items: 'para seus próximos projetos de design.',
				suffix_asset_items: 'para seus próximos projetos de design.',
			},

			elements: 'elementos',
			packs: 'pacotes',
			illustrations: 'ilustrações',
			designs: 'designs',
			Elements: 'Elementos',
			Packs: 'Pacotes',
			Illustrations: 'Ilustrações',
			Mockups: 'Mockups',
			Designs: 'Designs',
			empty: 'Não se preocupe! Explore outros tipos de ativos, ou',
			request_new: 'solicite novos',
			cant_find: 'Não consegue encontrar',
			browse_relevants: 'Explore pesquisas relevantes',
		},
		my_projects: {
			title: 'Projetos',
			description: 'Personalize e gerencie facilmente todos os seus projetos em um só lugar.',
			header: 'Meus Projetos',
			search_placeholder: 'Pesquisar arquivo',
			new_design: 'Novo Design',
			default: 'Padrão',
			advanced_editor: 'Editor Avançado',
			all: 'Animado + Estático',
			design: 'Estático',
			video: 'Animado',
			edited: 'Editado',
			duplicate_loading: 'Duplicando projeto....',
			sort: {
				sort_by: 'Ordenar Por',
				recent: 'Recente',
				alphabetical: 'Alfabético',
				oldest: 'Mais antigo',
			},

			menu: {
				open_in_advanced_editor: 'Abrir no Editor Avançado',
				duplicate: 'Duplicar',
				duplicate_submission: 'Duplicar como submissão',
				rename: 'Renomear',
				delete: 'Excluir',
			},

			empty_state: {
				title: 'Vamos preencher seus projetos!',
				description: 'Esta página está vazia agora. É hora de começar a personalizar ativos 3D.',
			},
		},
		my_assets: {
			title: 'Downloads',
			description: 'Veja todos os seus ativos baixados aqui. Você pode baixar novamente seus ativos a qualquer momento.',
			header: 'Meus Ativos',
			search_placeholder: 'Pesquisar arquivo',
			sort: {
				sort_by: 'Ordenar Por',
				oldest: 'Mais antigo',
				newest: 'Mais recente',
				alphabet_az: 'Alfabético (A-Z)',
			},
			type: {
				all: 'Todos os tipos',
				default_render: 'Renderização Padrão',
				high_quality: 'Alta qualidade',
				ai_generated: 'Gerado por IA',
			},

			downloads: {
				downloads: 'Downloads',
				file: 'Arquivo',
				download_status: 'Status do download',
				download_date: 'Data do download',

				empty_state: {
					title: 'Vamos preencher seus downloads!',
					description: 'Esta página está vazia agora. É hora de começar a baixar ativos legais.',

					heading_1: 'Desculpe, nenhum resultado encontrado',
					heading_2: 'Desculpe, não conseguimos encontrar correspondências para “{search}”',
					text: 'Sua página de Downloads está se sentindo um pouco solitária agora. É o momento certo para começar a exportar itens legais.',
					text_search: 'Tente ajustar sua pesquisa ou filtro para encontrar o que você está procurando.',
					button: 'Explorar mais',
				},
			},
			purchases: {
				purchases: 'compras',
				file: 'Arquivo',
				purchase_date: 'Data da Compra',
				open_file: 'Abrir Arquivo',
				empty_state: {
					title: 'Sua compra está vazia.',
					text: 'Vamos preencher este espaço com seus itens.',
					heading_1: 'Desculpe, nenhum resultado encontrado',
					heading_2: 'Desculpe, não conseguimos encontrar correspondências para “{search}”',
					text_search: 'Tente ajustar sua pesquisa ou filtro para encontrar o que você está procurando.',
					button: 'Explorar mais',
				},
				order_no: 'Número do pedido',
			},
		},
		upgrade_plan: {
			discount_text: 'Aproveite {discount_program}: {discount}% de desconto em seu plano!',
			end_in: 'Oferta por tempo limitado termina em',
			header: 'Desbloqueie todo o <br> poder do 3D com Pixcap',
			is_pro:
				'Obrigado por ser um assinante valioso! Você está desfrutando de nossos preços antigos e mais baixos até sua próxima renovação com a nova tarifa.',
			monthly: 'Mensal',
			quarterly: '3 Meses',
			yearly: 'Anual',
			save_up: 'economize até 33%',
			per_month: '/mês',
			recommended: 'Recomendado',
			current_short: 'Atual',
			current_plan: 'Plano atual',
			contact_sales: 'Contatar Vendas',
			get_plan: 'Obter {plan}',
			bill_annually: 'cobrado {amount} anualmente',
			bill_quarterly: 'cobrado {amount} por 3 meses',
			free: {
				free: 'Grátis',
				text: 'Texto',
				forever: 'Para sempre',
			},
			pro: {
				pro: 'Pro',
				text: 'Desbloqueie recursos premium para projetos mais profissionais.',
			},
			elite: {
				elite: 'Elite',
				text: 'Para grandes projetos com capacidades aprimoradas.',
			},
			enterprise: {
				enterprise: 'Empresarial',
				text: 'Soluções avançadas e suporte personalizado para projetos de alto nível.',
				custom: 'Personalizado',
			},
			benefits: {
				limited_free_assets: 'Ativos gratuitos limitados',
				images_per_month: '{number} imagens de alta qualidade por mês',
				videos_per_month: '{number} vídeos de alta qualidade por mês',
				exclusive_character_poses: 'Poses de personagens exclusivas',
				exclusive_materials_presets: 'Materiais e predefinições exclusivos',
				commercial_license: 'Licença comercial',
				all_premiums: 'Todos os ativos premium',
				export_3d_files: 'Exportações de arquivos 3D',
				unlimited_images: 'Imagens de alta qualidade ilimitadas',

				upto_10_projects: 'Até 10 projetos',
				monthly_credits_30: '30 créditos mensais em Super Downloads e Pixcap AI',
				commercial_license_with_watermark: "Licença comercial (com marca d'água)",

				upto_50_projects: 'Até 50 projetos',
				monthly_credits_600: '600 créditos mensais em Super Downloads e Pixcap AI',
				super_download: 'Super Downloads 3x mais rápidos (imagem)',

				unlimited_projects: 'Projetos ilimitados',
				monthly_credits_2000: '2000 créditos mensais em Super Downloads e Pixcap AI',
				dedicated_support: 'Suporte dedicado',
				everything_in_pro: 'Tudo no Pro',
				modeling_services: 'Serviços de modelagem 3D',
				unlimited_credits: 'Créditos ilimitados',
				custom_animations: 'Animações personalizadas',
				custom_mockups: 'Mockups personalizados',
				everything_in_elite: 'Tudo no Elite',
			},
			vat_included: 'Imposto sobre Valor Agregado (IVA) incluído',
			credit_cards: 'Aceita cartões de crédito e débito',
			cancel_anytime: 'Cancele a qualquer momento',
			trusted_by: 'Confiado por',
			show_full: 'Mostrar Completo',
			hide: 'Ocultar',
			limit_per_day: '{number} por dia',
			limit_per_month: '{number} por mês',
			unlimited: 'Ilimitado',
			limited: 'Limitado',
			medium: 'Médio',
			fast: 'Rápido (3x)',
			customization: {
				customization: 'Personalização',
				all_premiums: 'Todos os ativos 3D premium (ícones, personagens e mockups)',
				all_character_poses: 'Todas as poses de personagens',
				all_materials_presets: 'Todos os materiais e predefinições',
			},
			exports: {
				exports: 'Exportações',
				tooltip:
					'Você pode exportar imagens/vídeos em qualidade Padrão ou Alta: <br> &#x2022; Padrão: Renderização de qualidade padrão. <br> &#x2022; Alta qualidade: Ray-tracing aprimorado, sombras e nitidez para imagens/vídeos mais detalhados e profissionais.',
				standard: 'Imagens e vídeos padrão',
				hq_images: 'Imagens de alta qualidade (HQ)',
				hq_videos: 'Vídeos de alta qualidade (HQ)',
				rendering_speed: 'Velocidade de renderização (para imagens)',
				resolution_4k_export: 'Exportações em resolução 4K',
				file_3d_export: 'Exportações de arquivos 3D (GLTF/GLB)',
			},
			features: {
				features: 'Recursos',
				all_premiums: 'Todos os gráficos 3D premium (modelos, ícones e cenas)',
				free_only: 'Apenas gratuitos',
				graphics_upload: 'Upload de gráficos',
				fonts_upload: 'Upload de fontes',
				custom_artboard_sizes: 'Tamanhos de prancheta personalizados',
				resolution_4k_export: 'Exportações em resolução 4K',
				file_3d_export: 'Exportações de arquivos 3D (GLTF/GLB)',
			},
			credit_based_features: {
				credit_based_features: 'Recursos baseados em créditos',
				super_download: 'Super Download',
				super_download_tooltip:
					'O Super Download fornece alta qualidade <br> ray-tracing, sombras e nitidez <br> para imagens/vídeos detalhados e profissionais. <br> &#x2022; 1 crédito por imagem <br>  &#x2022; 1 crédito por 0,1 segundo de vídeo',
				rendering_speed: 'Velocidade de renderização (para imagem)',
				pixcap_ai: 'Pixcap AI',
				pixcap_ai_tooltip: '1 crédito por geração de IA',
				custom_ai_prompt: 'Prompt de IA personalizado',
				private_ai_output: 'Saídas de IA privadas',
				background_remover: 'Removedor de fundo',
				monthly_credits: 'créditos mensais',
				super_and_ai: '(Super Downloads e Pixcap AI)',
				unlimited: 'Ilimitado',
				medium: 'Médio',
				fast: 'Rápido (3x)',
			},
			ai_style_transfer: {
				ai_style_transfer: 'Transferência de Estilo de IA',
				tooltip:
					'Transforme instantaneamente seus ícones em diferentes estilos como Fosco, Brilhante, Tecido, Vidro, Inflado e muito mais com o Pixcap AI.',
				ai_style_transfer_usage: 'A Transferência de Estilo de IA usa',
				custom_ai_prompt: 'Prompt de IA personalizado',
				bg_removal: 'Removedor de fundo',
			},
			licensing: {
				licensing: 'Licenciamento',
				personal_use: 'Uso pessoal',
				commercial_use: 'Uso comercial',
				watermark: "Marca d'água",
			},
			tailored_services: {
				tailored_services: 'Serviços Personalizados',
				modeling_services: 'Serviços de modelagem 3D',
				custom_animations: 'Animações personalizadas',
				custom_mockups: 'Mockups personalizados',
				dedicated_support: 'Suporte dedicado',
			},
			support: 'Suporte',
			faq: {
				pricing: 'Preços',
				faqs: 'Perguntas Frequentes',
				what_is_hq_download: 'O que é Download de Alta Qualidade?',
				what_is_hq_download_answer:
					'As imagens e vídeos exportados terão qualidade muito superior, apresentando ray tracing aprimorado e sombras e iluminação mais realistas.',
				what_is_super_download: 'O que é Super Download?',
				what_is_super_download_answer:
					'As imagens e vídeos exportados no formato Super terão qualidade muito superior, apresentando ray tracing aprimorado e sombras e iluminação mais realistas.',
				can_i_use_pixcap_for_free: 'Posso usar o Pixcap gratuitamente?',
				can_i_use_pixcap_for_free_answer:
					'Certamente! O Pixcap oferece um plano gratuito para todos. Você pode navegar por todos os modelos e conteúdos sem nenhum custo. Se você gostar de um ativo PRO, ou modelo, quiser melhorar seu jogo de IA, ou usar a ferramenta de mockup animado, você pode atualizar seu plano para Pro ou Elite, dependendo das suas necessidades. Com planos pagos, você pode usar o super download, download 4k, baixar arquivos GLB, acessar todo o conteúdo e experimentar mockups 3D animados.',
				will_my_subscription_automatically_renew: 'Minha assinatura será renovada automaticamente?',
				will_my_subscription_automatically_renew_answer:
					'Sim, dependendo do plano, mensal ou anual, sua assinatura será renovada de acordo até que você a cancele manualmente, a qualquer momento. Você ainda terá acesso aos benefícios do seu plano até o próximo ciclo de faturamento.',
				what_are_pixcap_credits: 'O que são Créditos Pixcap?',
				what_are_pixcap_credits_answer: `Os Créditos Pixcap permitem que você experimente todo o potencial do Pixcap! Basicamente, você precisa de créditos para Super Download, Pixcap AI e exportações de Animação Super Download, e cada ferramenta usa uma quantidade específica de créditos. Por exemplo, 1 exportação de imagem estática usando super render vale 1 crédito, enquanto 1 segundo de exportação de animação super render vale {amount} créditos. Você pode aprender mais sobre nossos créditos em Créditos Pixcap.`,
				how_do_my_pixcap_credits_renew: 'Como meus Créditos Pixcap são renovados?',
				how_do_my_pixcap_credits_renew_answer:
					'Sim, cada usuário gratuito tem 30 Créditos Pixcap gratuitos mensais. Caso você precise de mais créditos, mas não queira se comprometer com uma assinatura, você pode adquirir um de nossos Pacotes de Recarga.',
				do_free_users_also_have_pixcap_credits: 'Usuários gratuitos também têm Créditos Pixcap?',
				do_free_users_also_have_pixcap_credits_answer:
					'Seus Créditos Pixcap são redefinidos mensalmente no dia 1º do mês. Todos os créditos não utilizados do mês anterior serão perdidos - então certifique-se de usá-los todos!',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles: 'Usuários Pro ou Elite também podem comprar dos Pacotes de Recarga?',
				can_pro_or_elite_users_also_buy_from_the_top_up_bundles_answer:
					'Sim! Se você usou todos os seus créditos de assinatura, você pode comprar de nossos Pacotes de Recarga - você não tem um limite mensal, então pode comprar quantos precisar!',
				can_i_use_pixcap_for_commercial_purposes: 'Posso usar o Pixcap para fins comerciais?',
				can_i_use_pixcap_for_commercial_purposes_answer:
					'Todo o conteúdo exportado pode ser usado comercialmente, com atribuição necessária para usuários GRATUITOS. Usuários PRO e ELITE não precisam fornecer atribuição e podem usar todo o conteúdo do Pixcap comercialmente em seus projetos.',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription: 'Perderei acesso aos meus downloads se cancelar minha assinatura?',
				will_i_lose_access_to_my_downloads_if_i_cancel_my_subscription_answer:
					'Seus downloads ficarão em sua conta para sempre, independentemente da assinatura.',
				what_is_your_refund_policy: 'Qual é a política de reembolso?',
				what_is_your_refund_policy_answer:
					'Os pagamentos de assinaturas Pixcap não são reembolsáveis, a menos que você more na U.E. ou na Turquia, caso em que pode ser elegível para reembolso apenas se cancelar sua assinatura dentro de 14 dias após a compra e <span class="text-weight-700"> não tiver usado o serviço, ou seja, não tiver baixado nenhum recurso do Pixcap.</span>' +
					'<div class="mt-m">Para solicitar um reembolso, por favor, envie-nos um e-mail para <span class="text-color-primary-5 text-underline">support@pixcap.com</span> e inclua seu país de residência. </div>',
			},
			testimonials: {
				testimonials: 'Depoimentos',
				header: 'O que nossos clientes dizem sobre nós',
			},
			do_more: {
				do_more: 'Faça Mais',
				header: 'Faça o upgrade e explore uma suíte completa de poderosas ferramentas de design 3D e IA',
				text: 'Pixcap é uma plataforma baseada em navegador que oferece ícones 3D, gráficos e ilustrações personalizáveis. Muitos de nossos recursos são gratuitos, oferecendo a você uma solução completa para todas as suas necessidades de edição 3D. A criação de conta não é obrigatória, mas recomendada, pois permite que você armazene seu trabalho criativo para que possa editar e baixar seus ícones a qualquer momento.',
				upgrade_now: 'Faça o upgrade agora',
			},
		},
		user_settings: {
			account_settings: {
				header: 'Configurações da Conta',
				email_address: 'Endereço de e-mail',
				change_photo: 'Alterar foto',
				text: 'Visualize e atualize os detalhes da sua conta, perfil e muito mais.',
				profile_photo: 'Foto do perfil',
				account_settings: 'Configurações da conta',
				display_name: 'Nome de exibição',
				bio: 'Biografia',
				bio_placeholder: 'Apresente-se',
				save_photo: 'Salvar imagem',
			},
			invite_friends: {
				invite_friends: 'Convidar amigos',
				header: 'Convide amigos, receba recompensas instantaneamente',
				text: 'Compartilhe o link de convite com seus amigos e acompanhe suas recompensas ganhas.',
				invite: 'Convidar',
				by_link: 'Por link',
				copy: 'Copiar link',
				by_email: 'Por e-mail',
				enter_email: 'Digite o endereço de e-mail',
				send: 'Enviar e-mail',
				invitation_sent: 'Convite enviado!',
				how_it_works: 'Como funciona',
				note: 'O Programa de Indicação do Pixcap foi encerrado. A recompensa ganha abaixo é relevante apenas para aqueles que participaram do programa antes do seu encerramento.',
				earned_rewards: 'Recompensas ganhas',
				referrals: 'Indicações',
				date: 'Data',
				status: 'Status',
				earnings: 'Ganhos',
				no_rewards: 'Nenhuma recompensa ganha.',
				tooltip: {
					pending: `Aguardando seu amigo se <br> inscrever de {start_date}-{expired_date}.`,
					joined: 'Aguardando seu amigo <br> baixar pelo menos 01 design.',
				},
				claimed: 'Reivindicado',
				pending: 'Pendente',
			},
			subscription: {
				text: 'Visualize, gerencie ou cancele suas assinaturas.',
				billed_annually: 'cobrado anualmente',
				per_month: 'por mês',
				per_day: 'por dia',
				free_text: 'Este plano vem com ativos e recursos limitados.',
				free_text_2: 'Faça o upgrade para obter acesso ilimitado a mais de 15.000 ativos premium e downloads de alta qualidade.',
				downloads: 'Downloads',
				downloads_limit: {
					default: 'Imagem + vídeo de qualidade padrão',
					hq_image: 'Imagem de alta qualidade',
					hq_video: 'Vídeo de alta qualidade',
				},
				ai_style: 'Transferência de Estilo de IA',
				ai_limits: {
					ai_uses: 'Usos de IA',
				},
				topped_up: 'Recarregado',

				subscription: 'Assinatura',
				renew_plan: 'Renovar Plano',
				plan: 'Plano',
				on_free: 'Você está agora em uma conta GRATUITA',
				sub_expired: '**Sua assinatura expirou.',
				payment_unsucess: '**Seu pagamento não foi bem-sucedido, por favor tente assinar novamente.',
				payment_pending: '**Seu pagamento está pendente, por favor verifique para continuar o pagamento.',
				payment_invalid: '**Seu pagamento é inválido, por favor verifique e atualize seus detalhes de pagamento.',
				plan_to_renew: '**Por favor, escolha um plano para renovar.',
				check_payment: 'Verificar pagamento',
				go_to_payment: 'Ir para o pagamento',
				choose_a_plan: 'Escolher um plano',
				complete_payment_in_your_plan: 'Complete o pagamento para continuar seu {plan}',
				cancel_plan: 'Cancelar Plano',
				change_plan: 'Alterar Plano',
				plan_end_on: 'O plano termina em',
				next_payment: 'Próximo pagamento',
				view_all_invoice: 'Ver todas as faturas',
				credits: 'Créditos',
				current_monthly_credits: 'Saldo atual de créditos mensais da assinatura',
				credit_note: 'Estes créditos fazem parte da sua assinatura e são renovados mensalmente.',
				topup_balance: 'Saldo de recarga de créditos',
				topup: 'Recarregar créditos',
				note: 'Créditos que você ganhou através de recargas. Estes créditos não <br />terão um limite mensal e não serão gastos enquanto seus <br />créditos de assinatura estiverem disponíveis.',
				per_year: 'Por ano',
				for_3_months: 'Por 3 meses',
				get_more_create_more: 'Obtenha mais, crie mais!',
				save_more: 'Economize mais com o plano Anual',
				upgrade_plan: 'Upgrade de Plano',
				unlock_all: 'Desbloquear Todos os Recursos',
				topup_modal: {
					choose_a_bundle: 'Escolha um pacote',
					credits: 'Créditos',
					text: 'Equivalente a <strong>1 de 3</strong> ofertas abaixo:',
					super_render_images: 'Imagens Super Render',
					super_render_videos: 'Vídeos Super Render',
					images: '{credits} imagens',
					seconds: '{credits} segundos',
					ai: 'Gerações de IA',
					generations: '{credits} gerações',
					amount: 'Valor',
					purchase_now: 'Comprar agora',
				},
				cancel_sub_modal: {
					cancel_sub: 'Cancelar assinatura',
					sub: 'Você perderá todos os recursos premium assim que seu plano {plan} expirar em <strong>{expireDay}</strong>',
					sub_alt: 'Você perderá imediatamente todos os recursos premium assim que cancelar.',
					let_us_know: 'Nos diga por que você está saindo',
					write_feedback: 'Escreva algum feedback...',
					confirmed: 'Seu cancelamento está confirmado!',
					update_text: 'A assinatura pode levar alguns momentos para atualizar.',
					enjoy:
						'Você ainda pode desfrutar de todos os recursos do Pixcap {plan} até <strong>{expireDay}</strong>. Depois disso, você não terá mais acesso a nenhum recurso premium.',
					change_your_mind: 'Se você mudar de ideia, considere renovar seu plano nas Configurações de Assinatura 😊!',
					sad: 'Estamos tristes em ver você partir! Você ainda pode desfrutar do Pixcap como um usuário <strong>GRATUITO</strong>.',
					change_your_mind_2: 'Se você mudar de ideia, considere assinar novamente nas Configurações de Assinatura 😊!',
					keep: 'Manter Pixcap {name}',
					got_it: 'Entendi',
					reason: {
						reason_1: 'É muito caro',
						reason_2: 'Preciso de mais recursos',
						reason_3: 'Encontrei outro site similar',
						reason_4: 'Não uso com muita frequência',
						reason_5: 'Não há muitos recursos de qualidade',
						reason_6: `Não acho que o Pixcap {plan} vale o dinheiro`,
						reason_7: 'Encontrei outro site similar',
					},
				},
				plan_name: {
					pro_quarterly_plan: 'Plano Pro Trimestral',
					elite_quarterly_plan: 'Plano Elite Trimestral',
					free_plan: 'Plano GRATUITO',
					pro_annual_plan: 'Plano Pro Anual',
					elite_annual_plan: 'Plano Elite Anual',
					pro_monthly_plan: 'Plano Pro Mensal',
					elite_monthly_plan: 'Plano Elite Mensal',
				},
			},
			promotion: {
				promotion: 'Promoções',
				text: 'Acompanhe descontos de indicação e brindes semanais.',
				only_one: 'Apenas <span class="text-weight-600">01</span> recompensa baseada em assinatura por faturamento. Encontre sua favorita abaixo. 🌟',
				got_it: 'Entendi',
				no_promotions: 'Não há promoções disponíveis',
				watermark_promo: {
					title: 'Brindes de indicação',
					description: "Downloads ilimitados sem marca d'água em ativos gratuitos",
					valid_until: 'Válido até {date}',
					no_expiration_date: 'Sem data de expiração',
					unlimited_referrals: 'Indicações ilimitadas',
					learn_more: 'Saiba mais',
					claim: 'Reivindicar',
					claimed: 'Reivindicado',
				},
			},
			change_password: {
				change_password: 'Alterar Senha',
				current: 'Senha atual',
				current_placeholder: 'Digite a senha atual',
				new: 'Nova senha',
				new_placeholder: 'Digite a nova senha',
				confirm: 'Confirmação de senha',
				confirm_placeholder: 'Digite novamente a nova senha',
				error_1: 'A senha não pode estar vazia',
				error_2: 'A nova senha não pode estar vazia',
				error_3: 'A confirmação de senha não pode estar vazia',
				error_4: 'As senhas não coincidem',
				error_5: 'Senha incorreta',
			},
			email_notification: {
				text: 'Defina suas preferências para newsletters e alertas de atividade da conta.',
				email_notification: 'Notificações por E-mail',
				alerts_and_newsletter: 'Alertas e Newsletter',
				downloads: 'Downloads',
				downloads_text: 'Receba notificações quando as imagens de alta qualidade e IA estiverem disponíveis para download',
				events: 'Eventos',
				events_text: 'Seja notificado quando seus pacotes de eventos preferidos forem publicados',
				newsletters: 'Newsletters',
				newsletters_text: 'Receba newsletters de marketing com notícias e inspirações de design',
				account_activity: 'Atividade da conta',
				email_when: 'Me envie e-mail quando',
				comments: 'Alguém comentar no meu projeto',
				mentions: 'Alguém me mencionar',
				collab_invites: 'Eu receber convites para colaborar em projetos de outros',
				pending_actions: 'Ações pendentes',
				conversation: 'Conversas em projetos',
			},
			save: 'Salvar',
			save_changes: 'Salvar alterações',
		},
		shared_project: {
			search_placeholder: 'Pesquisar projeto compartilhado...',
			title: 'Compartilhado comigo',
		},
		contributor_profile: {
			pixcap: {
				display_name: 'Equipe Pixcap',
				description:
					'Tenha acesso a mais de 15,000+ ativos 3D personalizáveis projetados para UX/UI, tecnologia, viagens, negócios, educação, alimentação, esportes e muito mais. Você pode alterar cores, rotacionar, posicionar e adicionar efeitos para tornar os ícones, ilustrações e personagens verdadeiramente seus.',
			},
		},
	},
	payments: {
		method: 'Métodos de Pagamento',
		card_ewallets: 'Cartões / Carteiras Eletrônicas',
	},
	common_elements: {
		explore_item_type_banner: {
			icons: {
				title: 'Personalize ícones 3D e ícones animados',
				description:
					'Experimente o Editor 3D para ajustar cores, materiais e estilos de animação dos seus ícones 3D. Baixe ícones 3D gratuitos em PNG, GLB, GIF, MP4 para uso no Figma, Sketch, Adobe e muito mais.',
				btn_text: 'Explorar Ícones 3D',
			},
			characters: {
				title: 'Dê vida a personagens 3D',
				description:
					'Descubra a única ferramenta de posicionamento 3D que permite posicionar seus personagens 3D, modificar cores e muito mais, sem a necessidade de experiência em modelagem 3D.',
				btn_text: 'Explorar Personagens 3D',
			},
			mockups: {
				title: 'Crie mockups 3D animados em segundos',
				description:
					'Os mockups agora são totalmente personalizáveis - faça upload do seu rótulo para qualquer mockup animado e baixe-o em 4K em menos de 5 minutos.',
				btn_text: 'Explorar Mockups',
			},
		},
		status: {
			all: 'Todos os status',
			completed: 'Concluído',
			processing: 'Processando',
			failed: 'Falhou',
			cancelled: 'Cancelado',
			preparing: 'Preparando',
			removed: 'Removido',
		},
		packs_count: '{count} pacotes',
		items_count: '{count} itens',
		filter: {
			filters: 'Filtros',
			type: {
				type: 'Tipo',
				all: 'Todos',
				animated_static: 'Animado + Estático',
				animated: 'Animado',
				static: 'Estático',
			},
			price: {
				price: 'Preço',
				all: 'Todos',
				premium: 'Premium',
				free_paid: 'Grátis + Pago',
				free_premium: 'Grátis + Premium',
				free: 'Grátis',
				paid: 'Pago',
			},
			size: {
				size: 'Tamanho',
				all: 'Todos os Tamanhos',
			},
			pack_or_item: {
				view_as: 'Visualizar como',
				items: 'Itens',
				packs: 'Pacotes',
			},
		},
		all_assets: 'Todos os ativos',
		icons: 'Ícones 3D',
		characters: 'Personagens 3D',
		mockups: 'Mockups',
		premium: 'Premium',
		asset_packs: 'Pacotes de Ativos 3D',
		icon_packs: 'Pacotes de Ícones 3D',
		character_packs: 'Pacotes de Personagens 3D',
		mockup_pack: 'Pacotes de Mockups',
		edit: 'Editar',
		high_quality: 'Alta qualidade',
		default: 'Padrão',
		subscribe_and_edit: 'Assine e Edite',
		get_elite_for_unlimited_access: 'Obtenha Elite para acesso ilimitado',
		upgrade_to_elite: 'Faça upgrade para Elite',
		unlock_all_access: 'Desbloqueie acesso total',
		explore_now: 'Explore agora',
		by: 'por',
		new: 'Novo',
		copy_link: 'Copiar link',
		link_copied: 'Link copiado',
		or: 'ou',
		show_all: 'Mostrar Tudo',
		view_all: 'Ver tudo',
		view_more: 'Ver mais',
		free: 'Grátis',
		purchased: 'Comprado',
		animated: 'Animado',
		editable: 'Editável',
		tags: {
			all: 'Todos',
			trending: 'Em alta',
		},
		coming_soon: 'Em breve',
		get_started: 'Começar',
		more: 'mais',
		edit_in_3d: 'Editar em 3D',
		view_details: 'Ver Detalhes',
		read_more: 'Leia mais',
		download: 'Baixar',
		open: 'Abrir',
		delete_modal: {
			title: 'Excluir {entity}',
			message: 'Tem certeza de que deseja excluir este {entity}?',
			delete: 'Excluir',
		},
		empty: {
			with_search: {
				message: 'Nenhum resultado encontrado',
				sub_message: "Quer que criemos '{search}' {type} para você? Envie-nos uma",
			},
			request: 'Solicitação',
			message: 'Ops! Este lugar está vazio por enquanto.',
			sub_message: 'Tente ajustar seus filtros ou volte em breve para atualizações',
		},
		cancel: 'Cancelar',
		error_404: 'Desculpe, não conseguimos encontrar a página que você está procurando.',
		error_500: 'Algo deu errado, por favor tente novamente mais tarde.',
		under_maintain: 'Estamos realizando uma manutenção programada\ne estaremos de volta online em breve, obrigado pela sua paciência.',
		error_password_length: 'A senha requer pelo menos 8 caracteres ou números (sem espaços).',
		error_password_not_matched: 'A confirmação da senha não corresponde.',
		back_to_explore: 'Voltar para Explorar',
		retry: 'Tentar novamente',
		render_type: {
			cloud: 'Renderização na Nuvem',
			default: 'Renderização Padrão',
			ai: 'Estilo IA',
		},
		remove: 'Remover',
	},
	toast: {
		error: 'Algo deu errado. Por favor, atualize e tente novamente.',
		success_delete_project: 'Projeto {name} excluído com sucesso',
	},
	tag_name: {
		essential: 'essencial',
		brand_kit: 'kit de marca',
		device: 'dispositivo',
		fintech: 'fintech',
		ecommerce: 'e-commerce',
		healthcare: 'saúde',
		business: 'negócios',
		casual: 'casual',
		profession: 'profissão',
		laptop: 'laptop',
		smartphone: 'smartphone',
		branding: 'branding',
	},
	seo_meta: {
		customizable: 'Personalizável',
		free: 'Grátis',
		animated: 'Animado',
		text_3d_animated_icons: 'Ícones 3D Animados',
		text_3d_icons_illus: 'Ícones 3D e Ilustrações',
		text_animated_icons: 'ícones animados',
		text_icons_logos_symbols: 'ícones, logotipos, símbolos',
		title_item_detail: 'Baixe {keyWord} em PNG, GLB, GIF, MP4 - Pixcap',
		description_item_illustration_detail:
			'{keyWord} personalizável para projetos de design ✓ Mais de 100 ilustrações 3D relacionadas ✓ Alta resolução ✓ Grátis para uso comercial',
		description_item_character_detail:
			'{keyWord} personalizável para projetos de design ✓ Mais de 100 personagens 3D relacionados ✓ Alta resolução ✓ Grátis para uso comercial',
		description_item_icon_detail:
			'{keyWord} personalizável para projetos de design ✓ Mais de 100 ícones 3D relacionados ✓ Alta resolução ✓ Grátis para uso comercial',
		description_item_mockup_detail:
			'{keyWord} personalizável para projetos de design ✓ Mais de 100 mockups 3D relacionados ✓ Alta resolução ✓ Grátis para uso comercial',
		description_item_design_detail:
			'{keyWord} personalizável para projetos de design ✓ Mais de 100 designs 3D relacionados ✓ Alta resolução ✓ Grátis para uso comercial',
		title_brandkit_detail: 'Modelos de Mockup de Branding para Seu Negócio - Pixcap',
		description_brandkit_detail:
			'Crie uma identidade de marca impressionante online com nossos modelos de mockup de branding gratuitos e premium. Visualize seu design em logo, papelaria, dispositivos, materiais de escritório e muito mais.',
		title_pack_detail: 'Pacote de Ícones {packKeyWord} | Baixe {packSize} ícones e ilustrações 3D',
		description_pack_detail:
			'Pacote de ícones 3D {packKeyWord} personalizável para projetos de design ✓ Mais de 100 pacotes de ícones relacionados ✓ Alta resolução ✓ Grátis para uso comercial',
		title_library_icons: 'Pacotes de Ícones 3D {currentPlanQuery} {currentTypeQuery} {currentTagQuery} - Download grátis PNG, GLB, GIF, MP4',
		description_library_icons:
			'Encontre pacotes de ícones 3D {currentPlanQuery} {currentTypeQuery} {currentTagQuery} para design gráfico, UX/UI, apresentações e muito mais ✓ Personalize, baixe e compartilhe ✓ Alta resolução ✓ Grátis para uso comercial',
		title_library_characters: 'Personagens 3D {currentPlanQuery} {currentTypeQuery} {currentTagQuery} - Download grátis PNG, GLB, GIF, MP4',
		description_library_characters:
			'Encontre personagens 3D {currentPlanQuery} {currentTypeQuery} {currentTagQuery} para design gráfico, UX/UI, apresentações e muito mais ✓ Personalize, baixe e compartilhe ✓ Alta resolução ✓ Grátis para uso comercial',
		title_library_illusions: 'Ilustrações 3D{currentTypeQuery}{currentPlanQuery}{currentTagQuery} - Download grátis PNG, GLB, GIF, MP4',
		description_library_illusions:
			'Encontre ilustrações 3D{currentTypeQuery}{currentPlanQuery}{currentTagQuery} para design gráfico, UX/UI, apresentações e muito mais ✓ Personalize, baixe e compartilhe ✓ Alta resolução ✓ Grátis para uso comercial',
		title_library_mockups: 'Mockups 3D {currentPlanQuery} {currentTypeQuery} {currentTagQuery} - Download Grátis PNG, GLB, GIF, MP4',
		description_library_mockups:
			'Encontre mockups 3D {currentPlanQuery} {currentTypeQuery} {currentTagQuery} para UX/UI, portfólio, apresentações e muito mais ✓ Personalize, baixe e compartilhe ✓ Alta resolução ✓ Grátis para uso comercial',
		title_library_mockups_default: 'Crie Mockups 3D e Mockups 3D Animados em Segundos',
		description_library_mockups_default:
			'Gere imagens e vídeos de mockup 3D realistas para seus produtos, pôsteres, cartões de visita, capas de livros e muito mais. Gerador de mockup gratuito para uso online.',
		title_library_designs: 'Ícones 3D, Ilustrações, Mockups - Download Grátis PNG, GLB, GIF, MP4',
		description_library_designs:
			'Navegue e baixe mais de 10.000 elementos 3D para design gráfico, apresentações e projetos web ✓ Alta resolução ✓ Grátis para uso comercial.',
		title_search_prefix: ' - Download Grátis PNG, GLB, GIF, MP4',
		title_search_pack_list:
			'{totalItems} Pacotes de Ícones 3D {currentPlanQuery}{searchWord} {currentTypeQuery} - Download Grátis PNG, GLB, GIF, MP4',
		description_search_pack_list:
			'Personalize e baixe pacotes de ícones 3D {currentPlanQuery}{searchWord} {currentTypeQuery} ✓ Alta resolução ✓ Grátis para uso comercial ✓ Novos pacotes adicionados diariamente.',
		title_search_icon_list: '{totalItems} {currentPlanQuery}{searchWord} {currentTypeQuery} - Download Grátis PNG, GLB, GIF, MP4',
		description_search_icon_list:
			'Personalize e baixe {currentPlanQuery}{searchWord} 3D {currentTypeQuery} ✓ Alta resolução ✓ Grátis para uso comercial ✓ Novos pacotes adicionados diariamente.',
		title_search_scene_list: '{totalItems} Ilustrações 3D {currentPlanQuery}{searchWord} {currentTypeQuery} - Download Grátis PNG, GLB, GIF, MP4',
		description_search_scene_list:
			'Personalize e baixe ilustrações 3D {currentPlanQuery}{searchWord} {currentTypeQuery} ✓ Alta resolução ✓ Grátis para uso comercial ✓ Novas ilustrações adicionadas diariamente.',
		title_search_mockup_list: '{totalItems} Mockups 3D {searchWord} - Download Grátis PNG, GLB, GIF, MP4',
		description_search_mockup_list:
			'Personalize e baixe mockups 3D {searchWord} ✓ Alta resolução ✓ Grátis para uso comercial ✓ Novos mockups 3D adicionados diariamente.',
		title_search_template_list: '{totalItems} Designs 3D {searchWord} - Download Grátis PNG, GLB, GIF, MP4',
		description_search_template_list:
			'Personalize e baixe designs 3D {searchWord} ✓ Alta resolução ✓ Grátis para uso comercial ✓ Novos designs 3D adicionados diariamente.',
		contributor_page: {
			title: 'Portfólio de Designer 3D de {name} | Pixcap',
			description:
				'Navegue e baixe recursos de design 3D de alta qualidade criados por {name}. Explore a comunidade de designers do Pixcap e mais belos ativos 3D agora.',
		},
	},
};

export default br;
